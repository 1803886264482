import {
    Personal
} from '@/api/index'
// 地区选择
export async function getArea(data) {
    let res = await Personal.getArea(data)
    return res;
}
//地址详情
export async function getOne(data) {
    let res = await Personal.getOne(data)
    return res;
}
// 我的收货地址
export async function receivingAddress(data) {
    let res = await Personal.receivingAddress(data)
    return res;
}

// 添加收货地址
export async function addAddress(data) {
    let res = await Personal.addAddress(data)
    return res;
}

// 添加收货地址区域号
export async function getAreaCode(data) {
    let res = await Personal.getAreaCode(data)
    return res;
}

// 设置默认地址
export async function setDefaultPath(data) {
    let res = await Personal.setDeftArea(data)
    return res;
}


// 删除地址
export async function delAdd(data) {
    let res = await Personal.delAdd(data)
    return res;
}