<template>
  <div>
  <my-slot>
    <div class="my_index_c" slot="content">
      <h3>{{this.$t('address')}}</h3>
      <div>
        <ul>
          <li>
            <p>{{this.$t('addressInfo')}}:</p>
            <div class="my_index_rt">
              <el-cascader
                @change="handleChange"
                ref="address"
                :key="is_cascader"
                v-model="addressArr"
                :props="props"
                :placeholder="this.$t('plsSel')"
              ></el-cascader>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <p>{{this.$t('detail')}}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_2">
                <input
                  name=""
                  type="text"
                  :placeholder="this.$t('addressTip')"
                  v-model="address.recAddress"
                />
              </div>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <p>{{this.$t('ConsigneeName')}}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_2">
                <input
                  name=""
                  type="text"
                  :placeholder="this.$t('iptName')"
                  v-model="address.receiverName"
                />
              </div>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <p>{{this.$t('phone')}}</p>
            <div class="my_index_rt">
              <div class="my_index_rt_5">
                <select v-model="address.recCountryCode">
                  <template v-for="(v, i) in codeList">
                    <option :value="v.memo" :selected="i == 0" :key="i"
                      >{{ isForeign ? v.alias : v.dicName }}+{{
                        v.memo
                      }}</option
                    >
                  </template>
                </select>
                <input
                  name=""
                  type="number"
                  :placeholder="this.$t('phoneTip')"
                  v-model="address.receiverPhone"
                />
              </div>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <label class="my_index_rt_6">
              <input
                class="demo--radio"
                type="checkbox"
                name="demo-checkbox1"
                @click="isDefault"
                v-model="state"
              />
              <span class="demo--checkbox demo--radioInput"></span
              >{{this.$t('setDefault')}}
            </label>
          </li>
          <li>
            <div class="my_index_rt_4">
              <input
                name=""
                type="button"
                :value="this.$t('saveAddress')"
                @click="saveAddress"
              />
            </div>
          </li>
        </ul>
      </div>
      <div class="my_adds">
        <dl>
          <dd
            :class="{ on: actAddIndex == i }"
            v-for="(v, i) in myAddList"
            :key="v.owid"
            @mouseover="hoverAdd(i)"
          >
            <div class="pc_fl my_adds_lf">
              <h4>
                {{ v.receiverName }}
                <span>{{ v.receiverPhone }}</span>
                <i v-if="v.isDefault == 1">默认地址</i>
              </h4>
              <p>{{ v.region }} {{ v.recAddress }}</p>
            </div>
            <div class="pc_fr my_adds_rt" v-if="actAddIndex == i">
              <a v-if="v.isDefault == 0" @click="setDefault(v.owid, i)"
                >设为默认</a
              ><a @click="fixAdd(v.owid, i)"><img src="@/assets/img/79.png"/></a
              ><a @click="delAdd(v.owid, i)"
                ><img src="@/assets/img/80.png"
              /></a>
            </div>
            <div class="clear"></div>
          </dd>
        </dl>
      </div>
    </div>
  </my-slot>
  </div>
</template>

<script>
import { isForeign, showLayer, isEmpty, goTop } from "@/utils/com";
import MySlot from "@/components/component/MySlot.vue";
import {
  getArea,
  getOne,
  receivingAddress,
  addAddress,
  getAreaCode,
  setDefaultPath,
  delAdd,
} from "./model";
export default {
  data() {
    return {
      isForeign: isForeign(), //国内国外
      mainOwid: this.$route.query?.id || "", //修改时不为空
      is_cascader: Math.random(), //重新渲染标识key
      addressArr: [], //地址选择器绑定的数据
      props: {
        //地址选择器绑定的数据
        lazy: true,
        lazyLoad(node, resolve) {
          const { value = "", level } = node;
          getArea({
            dicType: isForeign() ? "10002" : "10001",
            fid: value,
          }).then((res) => {
            const nodes = Array.from(res).map((i) => ({
              value: i.owid,
              path: i.path,
              label: i.dicName,
              leaf: level >= 2,
            }));
            resolve(nodes);
          });
        },
      },
      value: "",
      state: false,
      address: {
        receiverName: "",
        receiverPhone: "",
        recRegionId: "",
        recRegionPath: "",
        recAddress: "",
        isDefault: 0,
        recCountryCode: "", //国际区号
      },
      //区号列表
      codeList: [],
      //我的收货地址
      myAddList: [],
      //默认高亮选中修改的index
      actAddIndex: 1,
    };
  },
  components: {
    MySlot,
  },
  created() {
    this.getmyAdd();
    //获取区域编码
    getAreaCode().then((res) => {
      this.codeList = res || [];
      if (!this.address.recCountryCode && res) {
        this.address.recCountryCode = res[0].memo;
      }
    });

    if (this.mainOwid) {
      this.fixAdd(this.mainOwid);
    }
  },
  methods: {
    //获取我的收货地址
    getmyAdd() {
      receivingAddress().then((res) => {
        Array.isArray(res) && (this.myAddList = res || []);
      });
    },
    //设置默认地址
    async setDefault(owid, index) {
      console.log();
      let res = await setDefaultPath({ owid });
      if (res) {
        this.myAddList.forEach((v, i) => {
          i == index ? (v.isDefault = 1) : (v.isDefault = 0);
        });
      }
    },

    //保存地址
    async saveAddress() {
      let {
        receiverName,
        recRegionId,
        recAddress,
        receiverPhone,
      } = this.address;
      if (isEmpty(recRegionId)) {
        showLayer("toast", "请选择地区");
        return;
      }
      if (isEmpty(recAddress)) {
        showLayer("toast", "请填写详细地址");
        return;
      }
      if (isEmpty(receiverName)) {
        showLayer("toast", "请填写姓名");
        return;
      }
      if (isEmpty(receiverPhone)) {
        showLayer("toast", "请填写手机号");
        return;
      }
      let res = await addAddress({ ...this.address, owid: this.mainOwid });
      if (res) {
        showLayer("toast", "success!");
        this.getmyAdd();
        this.mainOwid = "";
        for (let key in this.address) {
          key != "recCountryCode" && (this.address[key] = "");
        }
      }
    },

    //地址选择改变
    handleChange(value) {
      let lastV = value[value.length - 1];
      let path = this.$refs.address.getCheckedNodes()[0].data.path;
      this.address.recRegionId = lastV;
      this.address.recRegionPath = path;
    },

    //hover改变现实修改删除
    hoverAdd(index) {
      this.actAddIndex = index;
    },

    //修改地址
    async fixAdd(owid) {
      this.mainOwid = owid;
      let res = await getOne({ owid });
      if (res) {
        this.address = res;
        goTop(100);
        console.log("resresresres", res);
      }
    },

    //删除地址
    async delAdd(owid, index) {
      let res = await delAdd({ owid });
      if (res) {
        this.myAddList.splice(index, 1);
      }
    },

    isDefault() {
      // this.state = !this.state;
      if (this.state === true) {
        this.address.isDefault = 0;
      } else {
        this.address.isDefault = 1;
      }
    },
  },
};
</script>

<style>
.my_index_rt_5 select {
  font-size: 0.064rem;
  margin-left: 0.1rem;
}
.el-cascader .el-input .el-input__inner {
  width: 2rem;
  margin-left: 0.1rem;
}
.el-cascader-menu__list {
  width: 1rem;
  height: 1rem;
}
.demo--radio:checked+.demo--radioInput:after {
  margin-top: -6px;
}
</style>
